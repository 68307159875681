import styled from 'styled-components'
import { 
//  AiOutlineTwitter,
  AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineInstagram
} from 'react-icons/ai'
import Head from '../../graphics/head.png'

export default function Footer() {
  return (
    <Container>
      <FooterSection>
        <div>Bard Spesialkaffe</div>
        <div>Skippergata 7</div>
        <div>4328 Sandnes</div>
        <div>Norway</div>
      </FooterSection>
      <FooterSectionCenter>
        <HeadImage src={Head} alt="head" />
        <TermsLink href="/#/terms">Salgsbetingelser</TermsLink>
      </FooterSectionCenter>
      <FooterSectionRight>
        <a href="https://www.youtube.com/@bollefrokaffe" rel="noreferrer" target="_blank"><AiOutlineYoutube size="2em" /></a>
        <a href="https://www.facebook.com/bollefrokaffe" rel="noreferrer" target="_blank"><AiOutlineFacebook size="2em" /></a>
        <a href="https://www.instagram.com/bollefrokaffe" rel="noreferrer" target="_blank"><AiOutlineInstagram size="2em" /></a>
      </FooterSectionRight>
    </Container>
  )
}

const Container = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  padding: 10px;
  padding-bottom: 50px;
  flex-shrink: 0;
`

const HeadImage = styled.img`
  height: 60px;
  @media (max-width: 768px) {
    height: 40px;
  }
` 

const FooterSectionCenter = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const FooterSection = styled.div`
  width: 200px;
  font-size: 0.7em;
`

const FooterSectionRight = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-end;
  gap: 5px;

  & svg {
    cursor: pointer;
  }
`

const TermsLink = styled.a`
  display: block; 
  font-size: 0.6em;
  transform: translateY(10px);
`
