import styled from 'styled-components'
import { nav } from 'tiny-react-router'
import { useAccountStore } from '../../state'
import Home from '../../graphics/home.png'
import Logo from '../../graphics/head.png'

export default function Header() {
  const account = useAccountStore(s => s.account)

  const handleBannerClick = () => {
    if (account?.admin) nav('/admin')
    else nav('/')
  }

  return (
    <Container>
      <MenuItem onClick={() => nav('/')}>
        <img src={Home} alt="home" />
      </MenuItem>
      <Banner src={Logo} alt="logo" onClick={handleBannerClick} />
      <MenuItem>
        { account &&
          <Avatar onClick={() => nav('/account')}>
            <div>{account?.data?.given_name[0]}</div>
            <div>{account?.data?.family_name[0]}</div>
          </Avatar>
        }
        { !account &&
          <AvatarPlaceholder />
        }
      </MenuItem>
    </Container>
  )
}

const Container = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`

const MenuItem = styled.div`
  cursor: pointer;

  & img {
    height: 50px;
  }
`

const Banner = styled.img`
  width: auto;
  height: 80px;
  transform: translateY(8px);
  z-index: 3;
  cursor: pointer;
`

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  background-color: var(--color-splash-purple); 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  cursor: pointer;
`

const AvatarPlaceholder = styled.div`
  width: 50px;
  height: 50px;
`
