import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { terms } from './terms'

export default function Terms() {
  return (
    <TermsContainer>
      <MarkdownContainer>
        <ReactMarkdown>
          {terms}
        </ReactMarkdown>
      </MarkdownContainer>
    </TermsContainer>
  )
}

const TermsContainer = styled.div`
  background-color: var(--color-splash-purple);
  color: white;
`

const MarkdownContainer = styled.div`
  text-align: left;
  padding: 2em;
`
