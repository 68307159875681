import { create } from 'zustand'

export const useAccountStore = create((set) => ({
  account: null,
  setAccount: (account) => set((state) => ({ ...state, account: account })),
  fetchAccount: async () => {
    const account_res = await fetch('/api/me')
    if (account_res.status !== 200) return
    const account = await account_res.json()
    set((state) => ({ ...state, account: account }))
  } 
}))

export const useSubscriptionStore = create((set) => ({
  subscriptions: [],
  setSubscriptions: (subs) => set((state) => ({ ...state, subscriptions: subs })),
  fetchSubscriptions: async () => {
    const subs_res = await fetch('/api/subscriptions')
    if (subs_res.status !== 200) return
    const subs = await subs_res.json()
    set((state) => ({ ...state, subscriptions: subs }))
  } 
}))

export const useOrderStore = create((set) => ({
  orders: [],
  hasMore: false,
  setOrders: (orders) => set((state) => ({ ...state, orders: orders })),
  fetchOrders: async (max) => {
    const max_param = max ? `?max=${max}` : '?max=10'
    const orders_res = await fetch(`/api/orders${max_param}`)
    if (orders_res.status !== 200) return
    const payload = await orders_res.json()
    set((state) => ({ ...state, orders: payload.orders, hasMore: payload.has_more }))
  } 
}))

export const useProductStore = create((set) => ({
  products: [],
  fetchProducts: async () => {
    const prod_res = await fetch('/api/products')
    if (prod_res.status !== 200) return
    const prods = await prod_res.json()
    set((state) => ({ ...state, products: prods }))
  } 
}))

export const useConfigStore = create((set) => ({
  transportFee: 50,
  postalCodesNoFee: [],
  subscriptionDiscount: 1,
  fetchAppConfig: async () => {
    const config_res = await fetch('/api/config')
    if (config_res.status !== 200) return
    const config = await config_res.json()
    const transportFee = parseFloat(config.TRANSPORT_FEE)
    const subscriptionDiscount = parseFloat(config.SUBSCRIPTION_DISCOUNT)
    set((state) => ({ ...state, transportFee, subscriptionDiscount }))
  },
  fetchPostalCodesNoFee: async () => {
    const prod_res = await fetch('/api/config/nofee')
    if (prod_res.status !== 200) return
    const postalCodesNoFee = await prod_res.json()
    set((state) => ({ ...state, postalCodesNoFee }))
  } 
}))

;

(async () => {
  const as = useAccountStore.getState()
  const subs = useSubscriptionStore.getState()
  const prods = useProductStore.getState()
  const orders = useOrderStore.getState()
  const config = useConfigStore.getState()
  await as.fetchAccount()
  await subs.fetchSubscriptions()
  await prods.fetchProducts()
  await orders.fetchOrders()
  await config.fetchAppConfig()
  await config.fetchPostalCodesNoFee()
})()
