import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { 
  useConfigStore,
  useProductStore,
  useAccountStore
} from '../../shared/state'

export default function Campaign({ cid }) {
  const [grinds, setGrinds] = useState({})
  const [campaign, setCampaig] = useState(null)
  const account = useAccountStore(s => s.account)
  const products = useProductStore(s => s.products)
  const transportFee = useConfigStore(s => s.transportFee)
  const postalCodesNoFee = useConfigStore(s => s.postalCodesNoFee)

  useEffect(() => {
    const fetchCampaign = async () => {
      const res = await fetch(`/api/campaign/${cid}`)
      if (!res.ok) return setCampaig(null)
      const json = await res.json()
      setCampaig(json)
    }
    fetchCampaign()
    const interval = setInterval(fetchCampaign, 5000)
    return () => clearInterval(interval)
  }, [cid])

  if (!campaign) return (
    <Container>
      <h1>Ukjent kampanje</h1>
    </Container>
  )

  const handleChangeGrind = (e, pid) => {
    const update = {}
    update[pid] = e.target.value
    setGrinds({ ...grinds, ...update })
  }

  const handleCampaignOrder = async () => {
    const params = new URLSearchParams(grinds)
    window.location = `/api/c/${cid}/order?${params.toString()}` 
  }

  const total_price = parseInt(campaign.data.price)
  const product_price = total_price / campaign.data.products.length
  const campaign_products = campaign.data.products.map(pid => {
    const product = products.filter(p => pid === p.id)[0]
    if (!product) return null
    product.data.price = product_price
    return product
  }).filter(cp => cp != null)
  let transport_cost = parseFloat(0).toFixed(2) 
  if (account) transport_cost = postalCodesNoFee.indexOf(parseInt(account?.data?.address?.postal_code)) >= 0 ? parseFloat(0).toFixed(2) : transportFee.toFixed(2)
  const price = total_price + parseInt(transport_cost)

  const campaign_product_rows = campaign_products.map(cp => {
    return (
      <CampaignProduct key={cp.id}>
        <img src={cp.data.bag} alt="bag" />
        <SelectedProductGrind>
          <select onChange={(e) => handleChangeGrind(e, cp.id)}>
            <option value="bønner">Bønner</option>
            <option value="filtermalt">Filtermalt</option>
            <option value="kokmalt">Kokmalt</option>
            <option value="finmalt">Finmalt</option>
          </select>
        </SelectedProductGrind>
      </CampaignProduct>
    )
  })

  const priceLines = campaign_products.map(cp => {
    return (
      <CampaignProductPriceLine key={cp.id}>
        <CampaignProductPriceLineProduct>{cp?.data?.name}</CampaignProductPriceLineProduct>
        <CampaignProductPriceLinePrice>NOK <span>{cp?.data?.price.toFixed(2)},-</span></CampaignProductPriceLinePrice>
      </CampaignProductPriceLine>
    )
  }) 

  return (
    <Container>
      <h1>Kampanje!</h1>
      <p>
        Ved å benytte deg av denne kampanjen får du følgende produkter til en meget gunsting pris.<br/>
        Man vær rask, det er et begrenset antall tilgjengelige plasser!
      </p>
      <CampaignProducts>
        {campaign_product_rows}
      </CampaignProducts>
      <CampaignInfo>
        <CampaignProductPriceLines>
          {priceLines}
          <CampaignProductPriceLine>
            <CampaignProductPriceLineProduct>Frakt</CampaignProductPriceLineProduct>
            <CampaignProductPriceLinePrice>NOK <span>{transport_cost},-</span></CampaignProductPriceLinePrice>
          </CampaignProductPriceLine>
          <CampaignProductPriceLine className="total">
            <CampaignProductPriceLineProduct>Totalt</CampaignProductPriceLineProduct>
            <CampaignProductPriceLinePrice>NOK <span>{price.toFixed(2)},-</span></CampaignProductPriceLinePrice>
          </CampaignProductPriceLine>
        </CampaignProductPriceLines>
        <CampaignInfoSpots>Gjenværende plasser: {campaign.data.slots}</CampaignInfoSpots>
      </CampaignInfo>
      <CampaignOrderButton className="purple" onClick={handleCampaignOrder} disabled={campaign?.data?.slots === 0}>Bestill</CampaignOrderButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--color-splash-yellow);
  padding: 20px;
  gap: 20px;

  ${props => props.modal ? `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  };
  ` : ''}

  & h1,p {
    padding: 0;
    margin: 0;
  }
`

const CampaignProducts = styled.div`
  display: flex;
  gap: 10px;
`
const CampaignProduct = styled.div`
  display: flex;
  flex-direction: column;

  & img {
    width: 200px;
    @media (max-width: 768px) {
      width: 100px; 
    }
  }
`
const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const CampaignInfoSpots = styled.div`
  margin-top: 10px;
  font-size: 1em;
  font-weight: bold;
`
const CampaignOrderButton = styled.button`
  flex-shrink: 0;
  max-width: 410px;
`
const SelectedProductGrind = styled.div`
  & select {
    width: 100%;
  }
`
const CampaignProductPriceLines = styled.div`
  display: flex;
  flex-direction: column;
`
const CampaignProductPriceLine = styled.div`
  display: flex;
  font-size: 0.8em;
  &.total {
    font-weight: bold;
    font-size: 0.9em;
  }
`
const CampaignProductPriceLinePrice = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
`
const CampaignProductPriceLineProduct= styled.div`
  width: 80px;
  font-style: italic;
`

