import { format, addDays, isAfter } from 'date-fns'

export const format_date = (d, props) => {
  try {
    return format(d, 'dd.MM.yyyy')
  } catch(e) {
    return props?.failed || 'Ukjent feil'
  }
}

export const format_phone_number = (n) => (n || '').length === 10 ? n.slice(2) : n

export const get_sub_interval_days = (interval) => {
  switch (interval) {
    case 'ukentlig':
      return 7
    case 'annenhver uke':
      return 14
    case 'månedlig':
      return 30
    default:
      return 0
  }
}

export function getNextEventDate(lastEventDate, interval) {
  let nextEventDate = lastEventDate;

  // Keep adding 30 days until we find a date in the future
  while (isAfter(new Date(), nextEventDate)) {
    nextEventDate = addDays(nextEventDate, get_sub_interval_days(interval));
  }


  return nextEventDate;
}
