export const terms = `
### Våre Salgsbetingelser

Standard salgsbetingelser for forbrukerkjøp av varer over Internett

Innholdsfortegnelse:

Innledning

1. Avtalen
2. Partene
3. Priser
4. Avtaleinngåelsen
5. Ordrebekreftelse
6. Betaling
7. Levering m.v.
8. Risikoen for varen
9. Angrerett
10. Undersøkelsesplikt
11. Reklamasjon ved mangel og frist for å melde krav ved forsinkelse
12. Kjøperens rettigheter ved forsinkelse
13. Kjøperens rettigheter ved mangel
14. Selgerens rettigheter ved kjøperens mislighold
15. Garanti
16. Personopplysninger
17. Konfliktløsning
18. Kildeliste

 

Innledning:

Dette kjøpet er regulert av den nedenstående standard salgsbetingelser for forbrukerkjøp av varer over Internett. Med forbrukerkjøp menes her salg av vare til forbruker som ikke hovedsakelig handler som ledd i næringsvirksomhet, og når selgeren opptrer i næringsvirksomhet med salg av varer over internett. Kontrakten er utarbeidet og anbefalt brukt av Forbrukerombudet.

Forbrukerkjøp over Internett reguleres hovedsakelig av avtaleloven, forbrukerkjøpsloven, markedsføringsloven, angrerettsloven og ehandelsloven, og disse lovene gir forbruker ufravikelige rettigheter. Vilkårene i kontrakten skal ikke forstås som noen begrensning i de lovbestemte rettighetene, men oppstiller partenes viktigste rettigheter og plikter for handelen. Selgeren kan velge å tilby kjøperen bedre vilkår enn det som fremgår av disse salgsbetingelsene.

I tilfeller hvor kontrakten ikke direkte gir løsningen på en problemstilling, må kontrakten utfylles med relevante lovbestemmelser.

1. Avtalen

Avtalen mellom kjøper og selger består av opplysningene selgeren gir om kjøpet i bestillingsløsningen i nettbutikken (herunder blant annet opplysninger om varens art, mengde, kvalitet, andre egenskaper, pris og leveringsbetingelser), eventuell direkte korrespondanse mellom partene (for eksempel e-post) samt disse salgsbetingelsene.

Ved motstrid mellom opplysningene selgeren har gitt om kjøpet i bestillingsløsningen i nettbutikken, direkte korrespondanse mellom partene og vilkårene i salgsbetingelsene, går direkte korrespondanse mellom partene og opplysningene gitt i bestillingsløsningen foran salgsbetingelsene, så fremt det ikke strider mot bindende lovgivning.

2. Partene

Selger 

Firmanavn: \`Bard Spesialkaffe ENK\`  
Kontaktadresse: \`Frøyerhagen 8, 4328 Sandnes\`  
E-post: \`bjorn@hanafjedle.net\`  
Telefonnummer: \`47011370\`                 
Organisasjonsnummer: \`924 878 797\`

Kjøper er den person som foretar bestillingen.

3. Priser

Prisene, som er oppgitt i nettbutikken, inkluderer merverdiavgift.

Opplysninger om de totale kostnadene kjøperen skal betale, inklusive alle avgifter (merverdiavgift, toll, og lignende) og leveringskostnader (frakt, porto, fakturagebyr, emballasje med videre) samt spesifisering av de enkelte elementene i totalprisen, gis i bestillingsløsningen før bestilling er foretatt. (Vareleveranser til Svalbard eller Jan Mayen skal selges uten tillegg av merverdiavgift. (1)

 

4. Avtaleinngåelse

Avtalen er bindende for begge parter når kjøperens bestilling er mottatt av selgeren.

En part er likevel ikke bundet av avtalen hvis det har forekommet skrive- eller tastefeil i tilbudet fra selgeren i bestillingsløsningen i nettbutikken eller i kjøperens bestilling, og den annen part innså eller burde ha innsett at det forelå en slik feil.

 

5. Ordrebekreftelse

Når selgeren har mottatt kjøperens bestilling, skal selgeren uten ugrunnet opphold bekrefte ordren ved å sende en ordrebekreftelse til kjøperen.

Det anbefales at kjøperen kontrollerer at ordrebekreftelsen stemmer overens med bestillingen med hensyn til antall, varetype, pris osv. Er det ikke samsvar mellom bestillingen og ordrebekreftelsen, bør kjøperen ta kontakt med selger så snart som mulig.

 

6. Betaling

Selgeren kan kreve betaling for varen fra det tidspunkt den blir sendt fra selgeren til kjøperen.

Dersom kjøperen bruker kredittkort (2) eller debetkort (3) ved betaling, kan selgeren reservere kjøpesummen på kortet ved bestillingen i inntil 4 dager fra bestillingen. (4)

Ved betaling med kredittkort, vil lov om kredittkjøp m.m. komme til anvendelse. (5)

Tilbyr selgeren etterfakturering, skal fakturaen til kjøperen utstedes ved forsendelse av varen. Forfallsfristen skal settes til minimum 14 dager fra kjøperen mottar forsendelsen.

Har selgeren særskilt behov for å kreve forskuddsbetaling fra kjøperen, for eksempel ved tilvirkningskjøp, kan selgeren kreve dette. 

Kjøpere under 18 år kan kun betale direkte ved selgerens levering av varen eller ved utlevering av varen ved postoppkrav. (6)

 

7. Levering m.v.

Levering av varen fra selgeren til kjøperen skjer på den måte, på det sted og til det tidspunkt som er angitt i bestillingsløsningen i nettbutikken.

Hvis ikke leveringstidspunkt fremgår av bestillingsløsningen, skal selgeren levere varen til kjøper innen rimelig tid og senest 30 dager etter bestillingen fra kunden. Skal selgeren sørge for at varen blir sendt til kjøperen, plikter han å få varen fraktet til bestemmelsesstedet på egnet måte og på vanlige vilkår for slik transport. Bestemmelsesstedet er hos kjøperen med mindre annet er særskilt avtalt mellom partene.

 

8. Risikoen for varen

Risikoen for varen går over på kjøperen når tingen er overtatt av kjøperen i henhold til avtalen. Hvis leveringstiden er kommet og kjøperen unnlater å overta en vare som er stilt til hans eller hennes rådighet etter avtalen, har kjøperen likevel risikoen for tap eller skade som skyldes egenskaper ved varen selv.

 

9. Angrerett

Kjøperen kan angre kjøpet av varen etter angrerettslovens bestemmelser (7). Angrerett innebærer at kjøperen uten grunn kan returnere varen til selgeren selv om det ikke er noen mangel ved den og selv om den ikke er levert.

Kjøperen må gi selger melding om bruk av angreretten innen 14 dager etter at varen, de foreskrevne opplysninger om angreretten og angrerettsskjema er mottatt. Mottar kjøperen angreskjema og de nødvendige opplysningene på et senere tidspunkt enn ved levering av varen, begynner angrefristen å løpe fra den dagen kjøperen mottar angrerettsskjema og opplysningene. Har kjøperen ikke mottatt tilstrekkelig informasjon eller angrerettsskjema, vil angrefristen likevel gå ut 3 måneder etter at varen er mottatt. Dersom kjøperen ikke har fått informasjon om angrerett i det hele tatt, vil fristen være 1 år.

Meldingen fra kjøper til selger om bruk av angreretten bør av bevishensyn være skriftlig (angrerettsskjema, e-post, telefaks eller brev), og den må inneholde opplysninger om hvordan kjøperen vil returnere varen til selgeren.

Ved bruk av angreretten må varen leveres tilbake til selgeren innen rimelig tid. Selgeren er forpliktet til å tilbakebetale hele kjøpesummen til kjøperen innen 14 dager fra den dag selgeren mottar varen eller henteseddel eller varen er stilt til selgerens rådighet. Selgeren kan ikke fastsette gebyrer for kjøperens bruk av angreretten, men selgeren kan kreve at kjøperen skal betale kostnadene for returforsendelsen.

Kjøperen kan undersøke produktet før han eller hun angrer på kjøpet. Varen må likevel kunne leveres tilbake til selgeren i tilnærmet samme stand og mengde som den var i da kjøperen mottok den. Kjøperen bør sende varen tilbake til selgeren i originalemballasjen hvis dette er mulig. 

Kjøperen kan ikke angre på kjøp av varer som forringes raskt, varer som etter sin art ikke kan tilbakeleveres, eller på lyd- og bildeopptak (herunder CDer, DVDer) eller datamaskinprogrammer hvor forseglingen er brutt. Det sistnevnte unntaket gjelder kun dersom selger klart og tydelig har opplyst om vilkårene for bortfall av angreretten på forseglingen.

 

10. Undersøkelse av varen

Når kjøperen mottar varen, anbefales det at han eller hun i rimelig utstrekning undersøker om den er i samsvar med bestillingen, om den har blitt skadet under transporten eller om den ellers har mangler.

Hvis varen ikke samsvarer med bestillingen eller har mangler, må kjøperen melde fra til selgeren ved reklamasjon jf. kontraktens punkt 11.

 

11. Reklamasjon ved mangel og frist for å melde krav ved forsinkelse

Dersom det foreligger en mangel ved varen, må kjøperen innen rimelig tid etter at han eller hun oppdaget den, gi selgeren melding om at han eller hun vil påberope seg mangelen.

Fristen kan aldri være kortere enn to måneder fra det tidspunkt da forbrukeren oppdaget mangelen. Reklamasjon må likevel skje senest to år etter at kjøperen overtok varen. Dersom varen eller deler av den er ment å vare vesentlig lengre, er reklamasjonsfristen fem år.

Ved forsinkelse må krav rettes selger innen rimelig tid etter at leveringstiden er kommet og varen ikke er levert.

Dersom varen er betalt med kredittkort, kan kjøperen også velge å reklamere og sende krav direkte til kredittyter (kredittkortselskapet).(8)

Meldingen til selgeren eller kredittyter bør være skriftlig (e-post, telefaks eller brev).

 

12. Kjøperens rettigheter ved forsinkelse

Dersom selgeren ikke leverer varen eller leverer den for sent i henhold til avtalen mellom partene, og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen i henhold til reglene i forbrukerkjøpslovens kapittel 5 etter omstendighetene holde kjøpesummen tilbake, kreve oppfyllelse, heve avtalen og kreve erstatning fra selgeren.

Oppfyllelse: Dersom selgeren ikke leverer varen på leveringstidspunktet, kan kjøperen fastholde kjøpet og sette en rimelig tileggsfrist for oppfyllelse fra selgeren. Kjøperen kan likevel ikke kreve oppfyllelse dersom det foreligger en hindring som selgeren ikke kan overvinne eller dersom oppfyllelse vil medføre en så stor ulempe eller kostnad for selgeren at det står i vesentlig misforhold til kjøperens interesse i at selgeren oppfyller. Faller vanskene bort innen rimelig tid, kan forbrukeren kreve oppfyllelse.

Heving: Kjøperen kan heve avtalen med selgeren dersom forsinkelsen er vesentlig eller hvis selgeren ikke leverer varen innen den tilleggsfristen for oppfyllelse som kjøperen har fastsatt. Kjøperen kan likevel ikke heve avtalen mens tilleggsfristen løper, med mindre selgeren har sagt at han eller hun ikke vil oppfylle innen fristen.

Erstatning: Kjøperen kan videre kreve erstatning for tap han eller hun lider som følge av forsinkelsen fra selgerens side jf. forbrukerkjøpslovens § 24.

Kjøperen må melde krav til selgeren ved reklamasjon jf. denne kontraktens punkt 11.

 

13. Kjøperens rettigheter ved mangel

Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter omstendighetene holde kjøpesummen tilbake, velge mellom retting og omlevering, kreve prisavslag, kreve avtalen hevet og erstatning fra selgeren.

Retting eller omlevering: Dersom varen har en mangel, kan kjøperen kreve at selgeren retter mangelen eller omleverer tilsvarende vare. Selgeren kan motsette seg kjøperens krav dersom gjennomføringen av kravet er umulig eller volder selgeren urimelige kostnader.

Selgeren skal foreta rettingen eller omleveringen innen rimelig tid. Retting eller omlevering skal foretas uten kostnad for kjøperen, uten risiko for at kjøperen ikke får dekket sine utlegg og uten vesentlig ulempe for kjøperen. Selgeren kan ikke foreta mer enn to forsøk på retting eller omlevering for samme mangel, med mindre det foreligger særlige grunner som gjør at ytterligere forsøk er rimelig.

Selv om kjøperen verken krever retting eller omlevering, kan selgeren tilby retting eller omlevering dersom dette skjer uten opphold. Dersom selgeren sørger for slik retting eller omlevering, kan kjøperen ikke kreve prisavslag eller heving.

Prisavslag: Dersom mangelen ikke rettes eller omleveres, kan kjøperen kreve forholdsmessig prisavslag.

Heving: I stedet for prisavslag kan kjøperen heve avtalen, unntatt når mangelen er uvesentlig.

Erstatning: Kjøperen kan også kreve erstatning for økonomisk tap han eller hun lider som følge av at varen har en mangel jf. forbrukerkjøpslovens § 33.

Kjøperen må melde krav til selgeren ved reklamasjon jf denne kontraktens punkt 11. Reglene om reklamasjon gjelder i tillegg til, og uavhengig av, reglene om angrerett og eventuelle garantier stilt av selger.

 

14. Selgerens rettigheter ved kjøperens mislighold

Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene etter avtalen, og dette ikke skyldes selgeren eller forhold på selgerens side, kan selgeren i henhold til reglene i forbrukerkjøpsloven kapittel 9 etter omstendighetene holde varen tilbake, kreve oppfyllelse av avtalen, kreve avtalen hevet samt erstatning fra kjøperen. Selgeren kan også etter omstendighetene kunne kreve renter ved forsinket betaling, inkassogebyr og gebyr ved ikke- forskuddsbetalte uavhentede varer.

Oppfyllelse: Dersom kjøperen ikke betaler, kan selgeren fastholde kjøpet og kreve at kjøperen betaler kjøpesummen (oppfyllelse). Er varen ikke levert, taper selgeren sin rett dersom han venter urimelig lenge med å fremme kravet.

Heving: Ved vesentlig betalingsmislighold eller annet vesentlig mislighold fra kjøper, kan selgeren heve avtalen. Selgeren kan likevel ikke heve etter at kjøpesummen er betalt.

Selgeren kan også heve kjøpet dersom kjøperen ikke betaler innen en rimelig tilleggsfrist for oppfyllelse som selgeren har fastsatt. Selgeren kan likevel ikke heve mens tilleggsfristen løper, med mindre kjøperen har sagt at han eller hun ikke vil betale.

Erstatning: Selgeren kan kreve erstatning fra kjøperen for økonomisk tap han eller hun lider som følge av kontraktsbrudd fra kjøperens side jf. forbrukerkjøpslovens §46.

Renter ved forsinket betaling/inkassogebyr: Dersom kjøperen ikke betaler kjøpesummen i henhold til avtalen, kan selgeren kreve renter av kjøpesummen etter lov om renter ved forsinket betaling.(9) Ved manglende betaling kan kravet, etter forutgående varsel, bli sendt til inkasso, og kjøperen kan da bli holdt ansvarlig for gebyrer etter lov om inkassovirksomhet og annen inndrivning av forfalte pengekrav.(10)

Gebyr ved uavhentede ikke-forskuddsbetalte varer: Dersom kjøperen unnlater å hente ubetalte varer, kan selgeren belaste kjøper med et gebyr på kr             + frakt tur/retur. Gebyret skal maksimalt dekke selgerens faktiske utlegg for å levere varen til kjøperen. Et slikt gebyr kan ikke belastes kjøpere under 18 år. (11)

 

15. Garanti

Garanti som gis av selgeren eller produsenten, gir kjøperen rettigheter i tillegg til de rettighetene kjøperen allerede har etter ufravikelig lovgivning. En garanti innebærer dermed ingen begrensninger i kjøperens rett til reklamasjon og krav ved forsinkelse eller mangler etter punkt 12 og 13.

 

16. Personopplysninger (12)

Denne personvernerklæringen gjelder alle forretningsprosesser i Bard Spesialkaffe ENK, heretter benevnt som Behandlingsansvarlig.

Erklæringen gir informasjon om databehandling utført av Behandlingsansvarlig og bestemmer formålet og virkemidlene for behandlingen samt databehandling vi gjør på vegne av våre kunder basert på deres instruksjoner.

Behandling av personlige data er nødvendig for at vi skal kunne ivareta våre kontraktsforpliktelser og betjene deg som kunde. Personlig data kan også benyttes til følgende formål: Salg, kontakt med kundeservice, markedsføring, informasjon, bokføring.

Ved markedsføring på e-post/sms innhenter et eksplisitt samtykke på forhånd.

Ved å gi oss dine personlige opplysninger, godtar du praksis og vilkår som er beskrevet i denne personvernerklæringen.

Om du har innsigelser med hensyn til vår behandling av personvern har du også anledning å klage til Datatilsynet.

Behandlingsansvarlig:

\`\`\`
Bard Spesialkaffe ENK
Frøyerhagen 8
4328 Sandnes
E-post: bjorn@hanafjedle.net 
Telefon: +47 47011554
\`\`\`

Personvernombud:

\`\`\`
E-post: bjorn@hanafjedle.net 
Telefon: +47 47011554
\`\`\`

Opplysninger kan registreres og lagres når du registrerer deg på https://bard.coffee, når du er i kontakt med salg og support enten via e-post eller når du bruker våre tjenester.

Hvor lagrer vi informasjon om deg – og hvor deler vi informasjonen om deg med andre?

Vår ehandelsplattform er egenutviklet, og informasjon om bestillingen lagres på våre servere i Norge. Vi deler ikke denne informasjonen med noen andre parter.

Underleverandører

Behandlingsansvarlig benytter seg også av tjenester levert av 3. Part. Her er en oversikt over underleverandører hvor informasjon kan lagres

Vipps:

Om en betaler med Vipps i nettbutikken (eller vår fysiske butikk) vil ditt telefonnummer sendes til Vipps for videre identifikasjon.

Bring:

Vi sender pakker med Bring og deler således informasjon relatert til frakt slik som navn, telefonnummer og adresse.

Postnord:

Vi sender pakker med Postnord og deler således informasjon relatert til frakt slik som navn, telefonnummer og adresse.

Kommunikasjon gjennom e-post og sms

Som kunde vil du kunne motta e-post og sms rundt ditt kundeforhold. F.eks vil vi sende deg e-post/sms relatert til ordrer du har lagt inn, sms med sporingsnummer, glemt passord, informasjon rundt din kundekonto, ol. Dette er definert som berettiget interesse og det vil ikke bes om samtykke til.

Vi benytter også e-post til markedsføring og kommunikasjon. Som kunde av Bard Spesialkaffe vil du med jevne mellomrom motta nyhetsbrev så fremst du har gitt et aktivt samtykke til det. Det er mulig å  melde seg av nyhetsbrev ved å klikke på avmeldingslinken i e-postene.

Innsyn og retting

Du har krav på innsyn i de opplysninger som er registrert om deg. Innsyn kan også fås ved å sende en henvendelse til bjorn@hanafjedle.net. Dersom de registrerte opplysninger ikke er riktige eller er ufullstendige, kan du kreve at opplysningene korrigeres i henhold til personopplysningsloven.

Oppbevaring og sletting

Opplysninger som ikke lenger er nødvendig for det formål de er laget for, slettes fra våre systemer.

Informasjon om bruk av informasjonskapsler.

En informasjonskapsel (cookie) er en liten tekstfil som lagres på din datamaskin.

Vi bruker informasjonskapsler blant annet til å huske din innlogging, passord i kryptert form, til å forbedre brukeropplevelsen og som en del av vår markedsføring mot nye kunder.

Når du besøker våre tjenester, setter vi både midlertidige og varige informasjonskapsler. En midlertidig informasjonskapsel slettes ofte automatisk når du lukker nettleseren din, mens varige kan ligge på din maskin i opptil ett år.

Om du ønsker å lære mer om informasjonskapsler kan http://www.aboutcookies.org benyttes.

Følgende informasjonskapsler er i bruk i våre tjenester

Bruk av våre butikkløsninger

Vi benytter informasjonskapsler til blant annet å huske deg som kunde og huske innhold i din handlekurv. Uten informasjonskapsler vil ikke en nettbutikk kunne fungere.

Samtykke i bruk av informasjonskapsler. I henhold til norsk lov har du automatisk samtykket i at vi lagrer informasjonskapsler på din datamaskin dersom du tillater dette gjennom innstillingene i din nettleser.

17. Konfliktløsning

Partene skal forsøke å løse eventuelle tvister i minnelighet. Kjøperen kan ta kontakt med Forbrukerrådet for å få bistand i en eventuell tvist med selger. Dersom minnelig løsning ikke oppnås etter megling i Forbrukerrådet, kan partene skriftlig begjære at Forbrukerrådet fremmer tvisten for Forbrukertvistutvalget.13 Vedtak av Forbrukertvistutvalget er rettskraftig fire uker etter forkynning. Før vedtaket er rettskraftig, kan partene, ved innsendelse av stevning til Forbrukertvistutvalget, bringe vedtaket inn for tingretten.

18. Kildeliste

* Se lov av 19. juni 1969 nr. 66 om merverdiavgift § 16.

* Et kredittkort er et betalingskort hvor oppgjøret for kjøpet skjer i etterkant ved at kredittgiveren (kredittkortselskapet) sender kortholder faktura med krav om betaling.

* Et debetkort er et betalingskort knyttet til en innskuddskonto. Bruk av kortet medfører at brukerens konto blir belastet og beløpet blir overført til betalingsmottakerens konto.

* Jf. mønsteravtale utarbeidet av det felles kontraktsutvalget for Sparebankforeningens og Finansnæringens hovedorganisasjon – Avtalevilkår for kredittkort og faktureringskort – forbrukerforhold punkt 12 og mønstervilkår utarbeidet av Sparebankforeningen og Finansnæringens hovedorganisasjon for betalingskort punkt 11.

* Lov av 21. juni 1985 nr. 82 om kredittkjøp m.m.

* Personer under 18 år kan kun betale på de nevnte måter ettersom de ikke kan stifte gjeld jf. lov av 22. april 1927 om vergemål for umyndige (vgml.) § 2.

* Lov av 21. desember 2000 nr. 105 om opplysningsplikt og angrerett m.v. ved fjernsalg og salg utenfor fast utsalgssted (angrerettloven).

* Lov av 17. desember 1976 nr. 100 om renter ved forsinket betaling.

* Lov av 13. mai 1988 nr. 26 om inkassovirksomhet og annen inndrivning av forfalte pengekrav.

* Gebyr kan ikke avkreves personer under 18 år da disse ikke kan stifte gjeld jf. vgml. § 2.

* Se lov av 14. april 2000 nr. 31 om behandling av personopplysninger.
` 
