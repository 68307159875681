import React from 'react'
import ReactDOM from 'react-dom/client'
import styled from 'styled-components'
import { Router } from 'tiny-react-router'
import Main from './screens/Main'
import Admin from './screens/Admin'
import Terms from './screens/Terms'
import Header from './shared/components/Header'
import Footer from './shared/components/Footer'
import Account from './screens/Account'
import Campaign from './screens/Campaign'
import './setupSentry'
import './index.css'

const routes = {
  '/': Main,
  '/terms': Terms,
  '/admin': Admin,
  '/account': Account,
  '/campaign/:cid': Campaign 
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ContainerInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
`

const RoutesContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Container>
      <ContainerInner>
        <Header />
        <RoutesContainer>
          <Router routes={routes} />
        </RoutesContainer>
        <Footer />
      </ContainerInner>
    </Container>
  </React.StrictMode>
);
